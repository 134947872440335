<template>
  <DashboardWrapper>
    <div class="container mx-auto">
      <div class="mx-4 sm:mx-8">
        <div class="flex items-center mb-4 mx-4 sm:mx-0">
          <h2 class="text-white uppercase text-xl font-extrabold">
            <i18n-t keypath="team.index.title" />
          </h2>
          <div
            class="numberCircle h-6 w-6 ml-3 flex justify-center items-center"
          >
            <span class="text-white text-sm font-medium"></span>
          </div>
        </div>

        <div class="flex justify-end mb-6">
          <div
            @click="state.isCreateOpen = true"
            class="my-auto text-white cursor-pointer flex group"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 mr-1 transition-all duration-150 group-hover:text-gray-400 transform group-hover:-translate-y-1"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                clip-rule="evenodd"
              />
            </svg>
            <p
              class="mr-6 group-hover:text-gray-400 transition-all duration-150"
            >
              <i18n-t keypath="team.create.title" />
            </p>
          </div>
        </div>

        <div class="">
          <div
            class="overflow-hidden bg-gray-900 border border-gray-800 shadow sm:rounded-md"
          >
            <ul role="list" class="divide-y divide-gray-200">
              <PaginationLoader
                :callback="indexTeam"
                v-model:isEmpty="state.isEmpty"
                data-key="teams"
                :reset-watcher="state.resetTeamListIncrementor"
                v-slot="{ item: team }"
              >
                <li class="group">
                  <router-link
                    :to="{ name: 'team.show', params: { id: team.id } }"
                    class="block hover:bg-gray-800 transition"
                  >
                    <div class="px-4 py-4 sm:px-6">
                      <div class="flex items-center justify-between">
                        <p class="truncate text-base font-medium text-white">
                          {{ team.name }}
                        </p>
                        <div class="ml-2 flex flex-shrink-0">
                          <p
                            class="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800"
                          >
                            <i18n-t keypath="team.show.trainees.active" />
                          </p>
                        </div>
                      </div>
                      <div class="mt-2 sm:flex sm:justify-between">
                        <div class="sm:flex">
                          <p class="flex items-center text-sm text-gray-500">
                            <!-- Heroicon name: mini/users -->
                            <svg
                              class="mr-2 h-5 w-5 flex-shrink-0 text-gray-400"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                d="M7 8a3 3 0 100-6 3 3 0 000 6zM14.5 9a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM1.615 16.428a1.224 1.224 0 01-.569-1.175 6.002 6.002 0 0111.908 0c.058.467-.172.92-.57 1.174A9.953 9.953 0 017 18a9.953 9.953 0 01-5.385-1.572zM14.5 16h-.106c.07-.297.088-.611.048-.933a7.47 7.47 0 00-1.588-3.755 4.502 4.502 0 015.874 2.636.818.818 0 01-.36.98A7.465 7.465 0 0114.5 16z"
                              />
                            </svg>
                            {{ team.trainee_count }}
                          </p>
                        </div>
                        <div
                          class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0"
                        >
                          <!-- Heroicon name: mini/calendar -->
                          <svg
                            class="mr-2 h-5 w-5 flex-shrink-0 text-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M5.75 2a.75.75 0 01.75.75V4h7V2.75a.75.75 0 011.5 0V4h.25A2.75 2.75 0 0118 6.75v8.5A2.75 2.75 0 0115.25 18H4.75A2.75 2.75 0 012 15.25v-8.5A2.75 2.75 0 014.75 4H5V2.75A.75.75 0 015.75 2zm-1 5.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25H4.75z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          <p>
                            <i18n-t keypath="team.index.created_at" />:
                            <time>{{
                              dateToHumanReadable(team.created_at)
                            }}</time>
                          </p>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </li>
              </PaginationLoader>
            </ul>
          </div>
        </div>

        <div v-if="state.isEmpty" class="text-white text-center mt-4">
          <i18n-t keypath="team.index.empty" />
        </div>

        <Modal v-model:isOpen="state.isCreateOpen">
          <h2 class="text-black text-xl font-bold uppercase">
            <i18n-t keypath="team.create.title" />
          </h2>
          <div class="mt-4">
            <form @submit.prevent="handleSubmit">
              <TextInput
                v-model:value="form.name"
                v-model:errors="errors.name"
                :label="$t('team.create.name.label')"
                :placeholder="$t('team.create.name.placeholder')"
                border="enable"
              />
              <div class="mt-4 flex">
                <button type="submit" class="button">
                  <i18n-t keypath="team.create.submit" />
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    </div>
  </DashboardWrapper>
</template>

<script>
import { defineComponent } from "vue";
import { dateToHumanReadable } from "@/helper/javascript/HumanReadableDate";
import DashboardWrapper from "@/wrappers/Dashboard";
import { indexTeam, createTeam } from "@/helper/api/TeamHelper";
import PaginationLoader from "@/components/PaginationLoader.vue";
import TextInput from "@/components/forms/TextInput.vue";
import Modal from "@/wrappers/partials/Modal.vue";

export default defineComponent({
  data() {
    return {
      form: {
        name: "",
      },
      errors: {},
      state: {
        isEmpty: false,
        isCreateOpen: false,
        resetTeamListIncrementor: 1,
      },
    };
  },
  methods: {
    indexTeam,
    dateToHumanReadable,
    async handleSubmit() {
      try {
        await createTeam(this.form);

        this.state.isCreateOpen = false;
        this.resetTeamList();
        this.form.name = "";
      } catch (err) {
        this.errors = err.response?.data.errors;
      }
    },
    resetTeamList() {
      this.state.resetTeamListIncrementor++;
    },
  },
  components: {
    DashboardWrapper,
    PaginationLoader,
    TextInput,
    Modal,
  },
});
</script>
